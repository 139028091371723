import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Team.scss";
import banner from "../../assets/banner_img1.jpg";
import linkedinLogo from "../../assets/linkedin.png";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);

  // Fetch team members from the backend
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(`${API_URL}/teammembers`);
        setTeamMembers(response.data);
      } catch (error) {
        console.error("Error fetching team members:", error);
        alert("Failed to fetch team members. Please try again.");
      }
    };

    fetchTeamMembers();
    window.scrollTo(0, 0);
  }, []);

  // Function to handle opening modal and setting selected member
  const openModal = (member) => {
    setSelectedMember(member);
  };

  // Function to handle closing modal
  const closeModal = () => {
    setSelectedMember(null);
  };

  return (
    <div className="team-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Team</div>
      </div>
      <h1 className="team-heading">Meet Our Team</h1>

      {/* Display Team Members Fetched from Backend */}
      <div className="founders">
        {teamMembers.map((member) => (
          <div
            key={member.id}
            className="team-member founder"
            onClick={() => openModal(member)}
          >
            <img
              src={`${API_URL}/teammembers/${member.id}/image`}
              alt={member.fullname}
            />
            <div className="team-member-info">
              <h3>{member.fullname}</h3>
              <p>{member.designation}</p>
            </div>
          </div>
        ))}
      </div>

      {selectedMember && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <img
              src={`${API_URL}/teammembers/${selectedMember.id}/image`}
              alt={selectedMember.fullname}
              className="modal-image"
            />
            <div className="modal-info">
              <h3>{selectedMember.fullname}</h3>
              <p>{selectedMember.designation}</p>
              {selectedMember.linkedinUrl && (
                <div className="modal-social-links">
                  <a
                    href={`${selectedMember.linkedinUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedinLogo} alt="LinkedIn" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="work-with-us-section">
        <h2>Work With Us</h2>
        <h3>Become a part of our team</h3>
        <p>
          Here at DEKAT Engineering, we have built a team of experts who are
          committed to their craft. We believe in working together as a
          committed community to get results for our clients.
        </p>
        <Link to="/career" className="careers-button">
          Careers
        </Link>
      </div>
    </div>
  );
};

export default Team;
